<template>
  <NuxtImg
    class="img"
    :data-url="src"
    :src="src || placeholder"
    :height="height"
    :width="width"
    :alt="alt"
    :placeholder="[width, height]"
  />
</template>

<script lang="ts" setup>
import type { Url } from '~/types'

type Props = Partial<{
  src: Url
  height: number
  width: number
  mime: string
  title: string
  alt: string
}>
const props = withDefaults(defineProps<Props>(), {
  height: 480,
  width: 480,
})

const random = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1) + min)

const randId = useId()
const rand = useState(randId, () => random(0, 14))

const placeholder = computed(() => `/placeholders/${rand.value}.jpg`)

const attrs = useAttrs()
const classes = computed(() => {
  const cls = (attrs?.class as string) || ''
  const overrides = Object.fromEntries(
    cls
      .split(' ')
      .map((c) => c.trim())
      .map((c) => [c, true]),
  )

  return {
    'w-100': !cls.includes('w-'),
    'h-auto': !cls.includes('h-'),
    'my-0': !cls.includes('my-'),
    'aspect-square': !cls.includes('aspect-'),
    'is-placeholder': !props.src,
    ...overrides,
  }
})

const _gcd = (w: number, h: number): number => {
  return h == 0 ? w : _gcd(h, w % h)
}

const gcd = (w?: number, h?: number): number => {
  if (!w || !h) return 1
  return _gcd(w, h)
}

defineOptions({
  // inheritAttrs: false,
})
</script>

<style lang="postcss" scoped>
.card-image {
  @apply max-w-[calc(100vw-2rem)];
  --tw-aspect-h: 9;
  --tw-aspect-w: 16;
}
</style>
